import { render, staticRenderFns } from "./NotificationComGymSub.vue?vue&type=template&id=5e6d5e52&scoped=true&"
import script from "./NotificationComGymSub.vue?vue&type=script&lang=js&"
export * from "./NotificationComGymSub.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e6d5e52",
  null
  
)

export default component.exports