import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import iziToast from 'izitoast';

Pusher.logToConsole = true;

export default {
  install(Vue, options = {}) {
  
    const echo = new Echo({
      broadcaster: 'pusher',
      key: options.key,         
      cluster: options.cluster, 
      forceTLS: options.forceTLS,
    });

    echo.channel('purchases')
    .listen('.purchase.made', (data) => {

      const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
        const audio = new Audio(require('@/assets/notification.mp3'));
        const track = audioCtx.createMediaElementSource(audio);
        track.connect(audioCtx.destination);
        
        audioCtx.resume().then(() => {
            audio.play().catch(console.error);
        });
        
        console.log(data)
        iziToast.success({
            title: `Notification: ${data.purchaseData.item}`,
            message: "",
            position: 'topRight', 
            timeout: 5000,  
            backgroundColor: '#FFA500', 
            icon: 'mdi mdi-bell',
            progressBarColor: '#FF4500', 
            titleColor: '#000', 
            messageColor: '#000',   
          })
    });

    Vue.prototype.$echo = echo;
  },
};
